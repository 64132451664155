.App

h1 {
  text-align: center;
  font-weight: 900;
  font-size: 2rem;
  text-transform: uppercase;
}

.App {
  background-color: #0077b6;
  min-height: 100vh;
  position: relative;
  padding-top: 8rem;
  color: #edf2f4;
}

.nav {
  background-color: #03045e;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 5rem;
  border-radius: 0 0 20px 20px;
  position: fixed;
  width: 100%;
  top: 0;
}

.icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 10%;
  cursor: pointer;
}

.nav-item span {
  margin-bottom: 10px;
}

#dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}

.member {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2b2d42;
  margin-bottom: 3rem;
  padding: 1rem 0;
  border-radius: 1rem;
}

.member-img {
  width: 75%;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.skills {
  display: flex;
}

.member h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  letter-spacing: 2px;
}

.member h3 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.hidden {
  display: none;
}